import { ActionContext, ActionTree } from "vuex"
import { RootState } from "../../index"
import { MutationType, Mutations } from "./mutations"
import { State } from "./state"
import { NotificationBanner } from "./types"

export enum ActionTypes {
    SET_NOTIFICATION_BANNER = "SET_NOTIFICATION_BANNER",
    SET_DEFAULT_LANGUAGE = "SET_DEFAULT_LANGUAGE",
    SET_LANGUAGE_LIST = "SET_LANGUAGE_LIST",
    SET_LANGUAGE_JSON = "SET_LANGUAGE_JSON",
    SET_IS_RESELLER = "SET_IS_RESELLER",
    SET_IS_PAID_USER = "SET_IS_PAID_USER",
    SET_IS_PROFILE_DATA_LOADED = "SET_IS_PROFILE_DATA_LOADED",
    SET_IS_CANDIDATE_DELETE = "SET_IS_CANDIDATE_DELETE",
    SET_IS_UNREAD_NOTIFICATIONS_COUNT = "SET_IS_UNREAD_NOTIFICATIONS_COUNT",
    SET_IS_DEFAULT_RESELLER = "SET_IS_DEFAULT_RESELLER",
    SET_IS_ADACOMPLIANCE = "SET_IS_ADACOMPLIANCE",
    SET_SESSION_ID = "SET_SESSION_ID",
    SET_DRAWER_VALUE = "SET_DRAWER_VALUE",
    SET_COMPANYTEST_DRAWER_VALUE = "SET_COMPANYTEST_DRAWER_VALUE",
    SET_RESELLER_NAME = "SET_RESELLER_NAME",
    SET_IS_DENYCREATEWORKSPACE = "SET_IS_DENYCREATEWORKSPACE",
    SET_SHOW_PLANS_TAB = "SET_SHOW_PLANS_TAB",
    SET_ADP_VISIBLE_TABLE_HEADINGS = "SET_ADP_VISIBLE_TABLE_HEADINGS",
    SET_ADP_CHECK_LIST_FILTER = "SET_ADP_CHECK_LIST_FILTER",
    SET_ADP_SORT_DETAILS = "SET_ADP_SORT_DETAILS",
    SET_ADP_FILTER_DETAILS = "SET_ADP_FILTER_DETAILS",
    SET_ALP_FILTER_DETAILS = "SET_ALP_FILTER_DETAILS",
    SET_ALP_VISIBLE_TABLE_HEADINGS = "SET_ALP_VISIBLE_TABLE_HEADINGS",
    SET_CLP_FILTER_DETAILS = "SET_CLP_FILTER_DETAILS",
    SET_TLP_FILTER_DETAILS = "SET_TLP_FILTER_DETAILS",
    SET_TALENT_FILTER_DETAILS = "SET_TALENT_FILTER_DETAILS",
    SET_QLP_FILTER_DETAILS = "SET_QLP_FILTER_DETAILS",
    SET_USER_FILTER_DETAILS = "SET_USER_FILTER_DETAILS",
    SET_ASS_TEST_TAB_FILTER_DETAILS = "SET_ASS_TEST_TAB_FILTER_DETAILS",
    SET_ASS_QUE_TAB_FILTER_DETAILS = "SET_ASS_QUE_TAB_FILTER_DETAILS",
    SET_COMPANY_QUE_TAB_FILTER_DETAILS = "SET_COMPANY_QUE_TAB_FILTER_DETAILS",
    SET_RESELLER_DOMAIN = "SET_RESELLER_DOMAIN",
    SET_RESELLER_STATUS = "SET_RESELLER_STATUS",
    SET_CURRENTUSER_DIALCODE = "SET_CURRENTUSER_DIALCODE",
    SET_CURRENTUSER_TIMEZONE = "SET_CURRENTUSER_TIMEZONE",
    SET_DISCOVER_PERCENTAGE = "SET_DISCOVER_PERCENTAGE",
    SET_RESELLER_MANAGE_LIMIT_STATUS = "SET_RESELLER_MANAGE_LIMIT_STATUS",
    SET_IS_TESTLIFY_SUPPORT_LOGIN = "SET_IS_TESTLIFY_SUPPORT_LOGIN",
    SET_SAML_SSO_AUTHENTICATION = "SET_SAML_SSO_AUTHENTICATION",
    SET_IS_VALID_CARD = "SET_IS_VALID_CARD",
}

type ActionAugments = Omit<ActionContext<State, RootState>, "commit"> & {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1]
    ): ReturnType<Mutations[K]>
}

export type Actions = {
    [ActionTypes.SET_NOTIFICATION_BANNER](
        { commit }: ActionAugments,
        value: NotificationBanner
    ): void
    [ActionTypes.SET_DEFAULT_LANGUAGE](
        { commit }: ActionAugments,
        lang: string
    ): void
    [ActionTypes.SET_LANGUAGE_LIST](
        { commit }: ActionAugments,
        value: Array<object>
    ): void
    [ActionTypes.SET_LANGUAGE_JSON](
        { commit }: ActionAugments,
        value: Object
    ): void
    [ActionTypes.SET_IS_RESELLER](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.SET_IS_DEFAULT_RESELLER](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.SET_IS_PAID_USER](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.SET_IS_DENYCREATEWORKSPACE](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.SET_IS_PROFILE_DATA_LOADED](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.SET_IS_CANDIDATE_DELETE](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.SET_IS_UNREAD_NOTIFICATIONS_COUNT](
        { commit }: ActionAugments,
        value: number
    ): void
    [ActionTypes.SET_IS_ADACOMPLIANCE](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.SET_SESSION_ID]({ commit }: ActionAugments): void
    [ActionTypes.SET_DRAWER_VALUE](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.SET_COMPANYTEST_DRAWER_VALUE](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.SET_RESELLER_NAME](
        { commit }: ActionAugments,
        value: string
    ): void
    [ActionTypes.SET_SHOW_PLANS_TAB](
        { commit }: ActionAugments,
        value: object
    ): void
    [ActionTypes.SET_ADP_VISIBLE_TABLE_HEADINGS](
        { commit }: ActionAugments,
        value: Array<string>
    ): void
    [ActionTypes.SET_ADP_CHECK_LIST_FILTER](
        { commit }: ActionAugments,
        value: Array<string>
    ): void
    [ActionTypes.SET_ADP_SORT_DETAILS](
        { commit }: ActionAugments,
        value: object
    ): void
    [ActionTypes.SET_ADP_FILTER_DETAILS](
        { commit }: ActionAugments,
        value: object
    ): void
    [ActionTypes.SET_ALP_FILTER_DETAILS](
        { commit }: ActionAugments,
        value: object
    ): void
    [ActionTypes.SET_ALP_VISIBLE_TABLE_HEADINGS](
        { commit }: ActionAugments,
        value: Array<string>
    ): void
    [ActionTypes.SET_CLP_FILTER_DETAILS](
        { commit }: ActionAugments,
        value: object
    ): void
    [ActionTypes.SET_TLP_FILTER_DETAILS](
        { commit }: ActionAugments,
        value: object
    ): void
    [ActionTypes.SET_TALENT_FILTER_DETAILS](
        { commit }: ActionAugments,
        value: object
    ): void
    [ActionTypes.SET_QLP_FILTER_DETAILS](
        { commit }: ActionAugments,
        value: object
    ): void
    [ActionTypes.SET_USER_FILTER_DETAILS](
        { commit }: ActionAugments,
        value: object
    ): void
    [ActionTypes.SET_ASS_TEST_TAB_FILTER_DETAILS](
        { commit }: ActionAugments,
        value: object
    ): void
    [ActionTypes.SET_ASS_QUE_TAB_FILTER_DETAILS](
        { commit }: ActionAugments,
        value: object
    ): void
    [ActionTypes.SET_COMPANY_QUE_TAB_FILTER_DETAILS](
        { commit }: ActionAugments,
        value: object
    ): void
    [ActionTypes.SET_RESELLER_DOMAIN](
        { commit }: ActionAugments,
        value: string
    ): void
    [ActionTypes.SET_RESELLER_STATUS](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.SET_RESELLER_MANAGE_LIMIT_STATUS](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.SET_CURRENTUSER_DIALCODE](
        { commit }: ActionAugments,
        value: string
    )
    [ActionTypes.SET_CURRENTUSER_TIMEZONE](
        { commit }: ActionAugments,
        value: string
    )
    [ActionTypes.SET_IS_TESTLIFY_SUPPORT_LOGIN](
        { commit }: ActionAugments,
        value: boolean
    )
    [ActionTypes.SET_DISCOVER_PERCENTAGE](
        { commit }: ActionAugments,
        value: number
    )
    [ActionTypes.SET_SAML_SSO_AUTHENTICATION](
        { commit }: ActionAugments,
        value: boolean
    )
    [ActionTypes.SET_IS_VALID_CARD]({ commit }: ActionAugments, value: boolean)
}

export const actions: ActionTree<State, RootState> & Actions = {
    [ActionTypes.SET_NOTIFICATION_BANNER]({ commit }, obj) {
        commit(MutationType.SET_NOTIFICATION_BANNER, obj)
    },
    [ActionTypes.SET_RESELLER_STATUS]({ commit }, value) {
        commit(MutationType.SET_RESELLER_STATUS, value)
    },
    [ActionTypes.SET_RESELLER_MANAGE_LIMIT_STATUS]({ commit }, value) {
        commit(MutationType.SET_RESELLER_MANAGE_LIMIT_STATUS, value)
    },
    [ActionTypes.SET_CURRENTUSER_DIALCODE]({ commit }, value) {
        commit(MutationType.SET_CURRENTUSER_DIALCODE, value)
    },
    [ActionTypes.SET_CURRENTUSER_TIMEZONE]({ commit }, value) {
        commit(MutationType.SET_CURRENTUSER_TIMEZONE, value)
    },
    [ActionTypes.SET_DEFAULT_LANGUAGE]({ commit }, lang) {
        commit(MutationType.SET_DEFAULT_LANGUAGE, lang)
    },
    [ActionTypes.SET_LANGUAGE_LIST]({ commit }, list) {
        commit(MutationType.SET_LANGUAGE_LIST, list)
    },
    [ActionTypes.SET_LANGUAGE_JSON]({ commit }, obj) {
        commit(MutationType.SET_LANGUAGE_JSON, obj)
    },
    [ActionTypes.SET_IS_RESELLER]({ commit }, value) {
        commit(MutationType.SET_IS_RESELLER, value)
    },
    [ActionTypes.SET_IS_DEFAULT_RESELLER]({ commit }, value) {
        commit(MutationType.SET_IS_DEFAULT_RESELLER, value)
    },
    [ActionTypes.SET_IS_PAID_USER]({ commit }, value) {
        commit(MutationType.SET_IS_PAID_USER, value)
    },
    [ActionTypes.SET_IS_DENYCREATEWORKSPACE]({ commit }, value) {
        commit(MutationType.SET_IS_DENYCREATEWORKSPACE, value)
    },
    [ActionTypes.SET_IS_PROFILE_DATA_LOADED]({ commit }, value) {
        commit(MutationType.SET_IS_PROFILE_DATA_LOADED, value)
    },
    [ActionTypes.SET_IS_CANDIDATE_DELETE]({ commit }, value) {
        commit(MutationType.SET_IS_CANDIDATE_DELETE, value)
    },
    [ActionTypes.SET_IS_UNREAD_NOTIFICATIONS_COUNT]({ commit }, value) {
        commit(MutationType.SET_IS_UNREAD_NOTIFICATIONS_COUNT, value)
    },
    [ActionTypes.SET_IS_ADACOMPLIANCE]({ commit }, value) {
        commit(MutationType.SET_IS_ADACOMPLIANCE, value)
    },
    [ActionTypes.SET_SESSION_ID]({ commit }) {
        commit(MutationType.SET_SESSION_ID, undefined)
    },
    [ActionTypes.SET_DRAWER_VALUE]({ commit }, value) {
        commit(MutationType.SET_DRAWER_VALUE, value)
    },
    [ActionTypes.SET_COMPANYTEST_DRAWER_VALUE]({ commit }, value) {
        commit(MutationType.SET_COMPANYTEST_DRAWER_VALUE, value)
    },
    [ActionTypes.SET_RESELLER_NAME]({ commit }, value) {
        commit(MutationType.SET_RESELLER_NAME, value)
    },
    [ActionTypes.SET_SHOW_PLANS_TAB]({ commit }, value) {
        commit(MutationType.SET_SHOW_PLANS_TAB, value)
    },
    [ActionTypes.SET_ADP_VISIBLE_TABLE_HEADINGS]({ commit }, obj) {
        commit(MutationType.SET_ADP_VISIBLE_TABLE_HEADINGS, obj)
    },
    [ActionTypes.SET_ADP_CHECK_LIST_FILTER]({ commit }, obj) {
        commit(MutationType.SET_ADP_CHECK_LIST_FILTER, obj)
    },
    [ActionTypes.SET_ADP_SORT_DETAILS]({ commit }, obj) {
        commit(MutationType.SET_ADP_SORT_DETAILS, obj)
    },
    [ActionTypes.SET_ADP_FILTER_DETAILS]({ commit }, obj) {
        commit(MutationType.SET_ADP_FILTER_DETAILS, obj)
    },
    [ActionTypes.SET_ALP_FILTER_DETAILS]({ commit }, obj) {
        commit(MutationType.SET_ALP_FILTER_DETAILS, obj)
    },
    [ActionTypes.SET_ALP_VISIBLE_TABLE_HEADINGS]({ commit }, obj) {
        commit(MutationType.SET_ALP_VISIBLE_TABLE_HEADINGS, obj)
    },
    [ActionTypes.SET_CLP_FILTER_DETAILS]({ commit }, obj) {
        commit(MutationType.SET_CLP_FILTER_DETAILS, obj)
    },
    [ActionTypes.SET_QLP_FILTER_DETAILS]({ commit }, obj) {
        commit(MutationType.SET_QLP_FILTER_DETAILS, obj)
    },
    [ActionTypes.SET_TLP_FILTER_DETAILS]({ commit }, obj) {
        commit(MutationType.SET_TLP_FILTER_DETAILS, obj)
    },
    [ActionTypes.SET_TALENT_FILTER_DETAILS]({ commit }, obj) {
        commit(MutationType.SET_TALENT_FILTER_DETAILS, obj)
    },
    [ActionTypes.SET_USER_FILTER_DETAILS]({ commit }, obj) {
        commit(MutationType.SET_USER_FILTER_DETAILS, obj)
    },
    [ActionTypes.SET_ASS_TEST_TAB_FILTER_DETAILS]({ commit }, obj) {
        commit(MutationType.SET_ASS_TEST_TAB_FILTER_DETAILS, obj)
    },
    [ActionTypes.SET_ASS_QUE_TAB_FILTER_DETAILS]({ commit }, obj) {
        commit(MutationType.SET_ASS_QUE_TAB_FILTER_DETAILS, obj)
    },
    [ActionTypes.SET_COMPANY_QUE_TAB_FILTER_DETAILS]({ commit }, obj) {
        commit(MutationType.SET_COMPANY_QUE_TAB_FILTER_DETAILS, obj)
    },
    [ActionTypes.SET_RESELLER_DOMAIN]({ commit }, value) {
        commit(MutationType.SET_RESELLER_DOMAIN, value)
    },
    [ActionTypes.SET_IS_TESTLIFY_SUPPORT_LOGIN]({ commit }, value) {
        commit(MutationType.SET_IS_TESTLIFY_SUPPORT_LOGIN, value)
    },
    [ActionTypes.SET_DISCOVER_PERCENTAGE]({ commit }, value) {
        commit(MutationType.SET_DISCOVER_PERCENTAGE, value)
    },
    [ActionTypes.SET_SAML_SSO_AUTHENTICATION]({ commit }, value) {
        commit(MutationType.SET_SAML_SSO_AUTHENTICATION, value)
    },
    [ActionTypes.SET_IS_VALID_CARD]({ commit }, value) {
        commit(MutationType.SET_IS_VALID_CARD as any, value)
    },
}
