import { GetterTree } from "vuex"
import { RootState } from "../../index"
import { State } from "./state"
import { NotificationBanner } from "./types"

export type Getters = {
    getNotificationBanner(state: State): NotificationBanner
    getResellerStatus(state: State): boolean
    getResellerManageLimitStatus(state: State): boolean
    getDefaultReseller(state: State): boolean
    getLanguageList(state: State): Array<object>
    getDefaultLanguage(state: State): string
    getLanguageData(state: State): Object
    getPaidUser(state: State): boolean
    getProfileData(state: State): boolean
    getIsCandidateDelete(state: State): boolean
    getUnreadNotificationCount(state: State): number
    getADAcompliance(state: State): boolean
    getSessionId(state: State): string
    getDrawerValue(state: State): boolean
    getCompanyTestDrawerValue(state: State): boolean
    getResellerName(state: State): string
    getDenyCreateWorkspace(state: State): boolean
    getVisibleTableHeadingsList(state: State): Array<string>
    getCheckListFilter(state: State): Array<string>
    getSortDetails(state: State): object
    getADPFilterDetails(state: State): object
    getALPFilterDetails(state: State): object
    getALPVisibleTableHeadingsList(state: State): Array<string>
    getCLPFilterDetails(state: State): object
    getTLPFilterDetails(state: State): object
    getTalentFilterDetails(state: State): object
    getQLPFilterDetails(state: State): object
    getUserFilterDetails(state: State): object
    getAssTestTabFilterDetails(state: State): object
    getAssQueTabFilterDetails(state: State): object
    getCompanyQueTabFilterDetails(state: State): object
    getResellerDomain(state: State): string
    getisResellerActive(state: State): boolean
    getAccessToken(state: State): string
    getCurrentUserCountryDialCode(state: State): string
    getCurrentUserTimezone(state: State): string
    isSupportLogin(state: State): boolean
    getDiscoverPercentage(state: State): number
    getSAMLSSOEnabledFlag(state: State): boolean
    getIsValidCard(state: State): boolean
}

export const getters: GetterTree<State, RootState> & Getters = {
    getNotificationBanner(state) {
        return state.notificationBanner
    },
    getResellerStatus(state) {
        return state.isReseller
    },
    getResellerManageLimitStatus(state) {
        return state.manageLimit
    },
    getDefaultReseller(state) {
        return state.isDefaultReseller
    },
    getLanguageList(state) {
        return state.locale.languageList
    },
    getDefaultLanguage(state) {
        return state.locale.defaultLanguage
    },
    getLanguageData(state) {
        return state.locale.languageJson
    },
    getPaidUser(state) {
        return state.isPaidUser
    },
    getProfileData(state) {
        return state.isProfileDataLoaded
    },
    getIsCandidateDelete(state) {
        return state.isCandidateDelete
    },
    getUnreadNotificationCount(state) {
        return state.unReadNotificationCount
    },
    getADAcompliance(state) {
        return state.ADAcompliance
    },
    getSessionId(state) {
        return state.sessionId
    },
    getDrawerValue(state) {
        return state.isDrawerOpen
    },
    getCompanyTestDrawerValue(state) {
        return state.isCompanyTestDrawerOpen
    },
    getResellerName(state) {
        return state.resellerName
    },
    getDenyCreateWorkspace(state) {
        return state.isDenyCreateWorkspace
    },
    getShowPlansTab(state) {
        return state.showPlansTab
    },
    getVisibleTableHeadingsList(state) {
        return state.ADPSession.visibleTableHeadings
    },
    getALPVisibleTableHeadingsList(state) {
        return state.websiteFilters.ALPVisibleTableHeadings
    },
    getCheckListFilter(state) {
        return state.ADPSession.checkListFilter
    },
    getSortDetails(state) {
        return state.ADPSession.sortDetails
    },
    getADPFilterDetails(state) {
        return state.ADPSession.filterDetails
    },
    getALPFilterDetails(state) {
        return state.websiteFilters.ALPFilterDetails
    },
    getCLPFilterDetails(state) {
        return state.websiteFilters.CLPFilterDetails
    },
    getTLPFilterDetails(state) {
        return state.websiteFilters.TLPFilterDetails
    },
    getTalentFilterDetails(state) {
        return state.websiteFilters.TalentFilterDetails
    },
    getQLPFilterDetails(state) {
        return state.websiteFilters.QLPFilterDetails
    },
    getUserFilterDetails(state) {
        return state.websiteFilters.userFilterDetails
    },
    getAssTestTabFilterDetails(state) {
        return state.websiteFilters.assTestTabFilterDetails
    },
    getAssQueTabFilterDetails(state) {
        return state.websiteFilters.assQueTabFilterDetails
    },
    getCompanyQueTabFilterDetails(state) {
        return state.websiteFilters.companyQueTabFilterDetails
    },
    getResellerDomain(state) {
        return state.resellerDomain
    },
    getisResellerActive(state) {
        return state.isResellerActive
    },
    getCurrentUserCountryDialCode(state) {
        return state.currentUserCountryDialCode
            ? state.currentUserCountryDialCode
            : "91"
    },
    getCurrentUserTimezone(state) {
        return state.currentUserTimeZone
            ? state.currentUserTimeZone
            : "Asia/Kolkata"
    },
    getAccessToken(state) {
        let token =
            document.cookie
                .match(
                    "(^|;)\\s*" + "sessionAccessKeyCookie" + "\\s*=\\s*([^;]+)"
                )
                ?.pop() || ""
        return token
    },
    isSupportLogin(state) {
        return state.isSupportLogin
    },
    getDiscoverPercentage(state) {
        return state.discoverPercentage
    },
    getSAMLSSOEnabledFlag(state) {
        return state.ssosamlEnabled
    },
    getIsValidCard(state) {
        return state.isValidCard
    },
}
