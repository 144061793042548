import { ActionContext, ActionTree } from "vuex"
import { Mutations, MutationType } from "./mutations"
import { RootState } from "../../index"
import { State } from "./state"
import { TypeCompanyQuestion, TypeCompanyTest } from "./types"

export enum ActionTypes {
    SET_COMPANY_TEST_DETAILS = "SET_COMPANY_TEST_DETAILS",
    SET_COMPANT_TEST_SKILLS = "SET_COMPANT_TEST_SKILLS",
    OPEN_SINGLE_SELECT_MODAL = "OPEN_SINGLE_SELECT_MODAL",
    OPEN_MULTIPLE_SELECT_MODAL = "OPEN_MULTIPLE_SELECT_MODAL",
    SET_COMPANY_TEST_QUESTION_LIST = "SET_COMPANY_TEST_QUESTION_LIST",
    SET_ACTIVE_ACCORDION = "SET_ACTIVE_ACCORDION",
    REMOVE_COMPANY_QUESTION_FROM_CART = "REMOVE_COMPANY_QUESTION_FROM_CART",
}

type ActionAugments = Omit<ActionContext<State, RootState>, "commit"> & {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1]
    ): ReturnType<Mutations[K]>
}

export type Actions = {
    [ActionTypes.SET_COMPANY_TEST_DETAILS](
        { commit }: ActionAugments,
        value: TypeCompanyTest
    ): void
    [ActionTypes.OPEN_SINGLE_SELECT_MODAL](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.OPEN_MULTIPLE_SELECT_MODAL](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.SET_COMPANY_TEST_QUESTION_LIST](
        { commit }: ActionAugments,
        list: TypeCompanyQuestion
    ): void
    [ActionTypes.SET_ACTIVE_ACCORDION](
        { commit }: ActionAugments,
        activeAccordion: string
    ): void
    [ActionTypes.SET_COMPANT_TEST_SKILLS](
        { commit }: ActionAugments,
        skills: any
    ): void
    [ActionTypes.REMOVE_COMPANY_QUESTION_FROM_CART](
        { commit }: ActionAugments,
        cartItemIndex: number
    ): void
}

export const actions: ActionTree<State, RootState> & Actions = {
    [ActionTypes.SET_COMPANY_TEST_DETAILS]({ commit }, obj) {
        commit(MutationType.SET_COMPANY_TEST_DETAILS, obj)
    },
    [ActionTypes.OPEN_SINGLE_SELECT_MODAL]({ commit }, value) {
        commit(MutationType.OPEN_SINGLE_SELECT_MODAL, value)
    },
    [ActionTypes.OPEN_MULTIPLE_SELECT_MODAL]({ commit }, value) {
        commit(MutationType.OPEN_MULTIPLE_SELECT_MODAL, value)
    },
    [ActionTypes.SET_COMPANY_TEST_QUESTION_LIST]({ commit }, list) {
        commit(MutationType.SET_COMPANY_TEST_QUESTION_LIST, list)
    },
    [ActionTypes.SET_COMPANT_TEST_SKILLS]({ commit }, skills) {
        commit(MutationType.SET_COMPANT_TEST_SKILLS, skills)
    },
    [ActionTypes.SET_ACTIVE_ACCORDION]({ commit }, activeAccordion) {
        if (activeAccordion) {
            commit(MutationType.SET_ACTIVE_ACCORDION, activeAccordion)
        }
    },
    [ActionTypes.REMOVE_COMPANY_QUESTION_FROM_CART]({ commit }, cartItemIndex) {
        commit(MutationType.REMOVE_COMPANY_QUESTION_FROM_CART, cartItemIndex)
    },
}
