export type State = {
    workspaceId: String
    workspaceUrl: String
    workspaceName: String
    workspaceLogo: String
    WorkspaceFavicon: String
    workspaceBrandColor: String
    workspaceDarkerBrandColor: String
    workspaceMidBrandColor: String
    workspaceLighterBrandColor: String
    subscriptionExpireDate: String
    status: String
    subscriptionName: String
    workspaceDarkestBrandColor: String
    workspaceLightGreyBrandColor: String
    isTrial: Boolean
    integrationAddOn: Boolean
    isAppSumoCouponApplied: Boolean
    whiteLabelAddOn: Boolean
    isBillingAllowed: Boolean
    isWhiteLabel: Boolean
    isFullDiscount: Boolean
    isPayPerUsagePlan: Boolean
    isLegacyPlan: Boolean
    candidateNotificationAllowed: Boolean
    isCustomTemplateActive: Boolean
    administratorNotificationAllowed: Boolean
    isEmailTemplateActive: Boolean
    supportEmail: String
    termsUrl: String
    privacyUrl: String
    IntroductionImage: String
    daysLeftForTrial: Number | null
    trialExtended: Boolean
}

export const state: State = {
    workspaceId: "",
    workspaceUrl: "",
    workspaceName: "",
    workspaceLogo: "",
    WorkspaceFavicon: "",
    workspaceBrandColor: "#DE3439",
    workspaceDarkerBrandColor: "#d9214e",
    workspaceMidBrandColor: "#fbf9f6",
    workspaceLighterBrandColor: "#fff",
    workspaceDarkestBrandColor: "#FFEBCC",
    workspaceLightGreyBrandColor: "#f3f6f9",
    subscriptionExpireDate: "",
    status: "",
    isTrial: false,
    isBillingAllowed: false,
    integrationAddOn: false,
    isAppSumoCouponApplied: false,
    isWhiteLabel: false,
    isFullDiscount: false,
    isPayPerUsagePlan: false,
    isLegacyPlan: false,
    subscriptionName: "",
    whiteLabelAddOn: false,
    candidateNotificationAllowed: true,
    isCustomTemplateActive: false,
    administratorNotificationAllowed: true,
    isEmailTemplateActive: false,
    supportEmail: "",
    termsUrl: "",
    privacyUrl: "",
    IntroductionImage: "",
    daysLeftForTrial: null,
    trialExtended: false,
}
