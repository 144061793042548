import helperFunction from "@/helper-functions/helperFunctions"
import { ActionTypes as WorkspaceActions } from "@/store/modules/workspace/actions"
import { ActionContext, ActionTree } from "vuex"
import { ActionTypes as GlobalActions } from "../global/actions"
import { MutationType, Mutations } from "./mutations"
import type { State } from "./state"
import {
    TypeAccessLogin,
    TypeAccessRegistration,
    TypeAuthUser,
    TypeDefaultLoginDetails,
    TypeRedirectAfterLogin,
} from "./types"
import { RootState } from "/src/store"
export enum ActionTypes {
    SET_API_ACCESS_REGISTRATION = "SET_API_ACCESS_REGISTRATION",
    EMPTY_API_ACCESS_REGISTRATION = "EMPTY_API_ACCESS_REGISTRATION",
    SET_AUTH_USER = "SET_AUTH_USER",
    SET_CURRENT_USER_ROLE = "SET_CURRENT_USER_ROLE",
    EMPTY_AUTH_USER = "EMPTY_AUTH_USER",
    SET_API_ACCESS_LOGIN = "SET_API_ACCESS_LOGIN",
    EMPTY_API_ACCESS_LOGIN = "EMPTY_API_ACCESS_LOGIN",
    LOG_OUT = "LOG_OUT",
    LOG_OUT_WITHOUT_REDIRECTION = "LOG_OUT_WITHOUT_REDIRECTION",
    SET_USER_REGISTERED = "SET_USER_REGISTERED",
    REDIRECT_AFTER_LOGIN = "REDIRECT_AFTER_LOGIN",
    SET_API_DEFAULT_LOGIN_DETAILS = "SET_API_DEFAULT_LOGIN_DETAILS",
    SET_BRAND_COLOR = "SET_BRAND_COLOR",
}

type ActionAugments = Omit<ActionContext<State, RootState>, "commit"> & {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1]
    ): ReturnType<Mutations[K]>
}

export type Actions = {
    [ActionTypes.SET_API_ACCESS_REGISTRATION](
        { commit }: ActionAugments,
        regObj: TypeAccessRegistration
    ): void
    [ActionTypes.EMPTY_API_ACCESS_REGISTRATION]({
        commit,
    }: ActionAugments): void
    [ActionTypes.SET_AUTH_USER](
        { commit }: ActionAugments,
        userDetails: TypeAuthUser
    ): void
    [ActionTypes.SET_CURRENT_USER_ROLE](
        { commit }: ActionAugments,
        role: string
    ): void
    [ActionTypes.EMPTY_AUTH_USER]({ commit }: ActionAugments): void
    [ActionTypes.SET_API_ACCESS_LOGIN](
        { commit }: ActionAugments,
        loginObject: TypeAccessLogin
    ): void
    [ActionTypes.SET_API_DEFAULT_LOGIN_DETAILS](
        { commit }: ActionAugments,
        defaultLoginObject: TypeDefaultLoginDetails
    ): void
    [ActionTypes.EMPTY_API_ACCESS_LOGIN]({ commit }: ActionAugments): void
    [ActionTypes.LOG_OUT]({ commit }: ActionAugments): void
    [ActionTypes.LOG_OUT_WITHOUT_REDIRECTION]({ commit }: ActionAugments): void
    [ActionTypes.SET_USER_REGISTERED](
        { commit }: ActionAugments,
        val: boolean
    ): void
    [ActionTypes.REDIRECT_AFTER_LOGIN](
        { commit }: ActionAugments,
        redirectObject: TypeRedirectAfterLogin
    ): void
}

export const actions: ActionTree<State, RootState> & Actions = {
    [ActionTypes.SET_API_ACCESS_REGISTRATION]({ commit }, regObj) {
        commit(MutationType.SET_API_ACCESS_REGISTRATION, regObj)
    },
    [ActionTypes.SET_USER_REGISTERED]({ commit }, val: boolean) {
        commit(MutationType.SET_USER_REGISTERED, val)
    },
    [ActionTypes.REDIRECT_AFTER_LOGIN]({ commit }, redirectObject) {
        commit(MutationType.REDIRECT_AFTER_LOGIN, redirectObject)
    },
    [ActionTypes.EMPTY_API_ACCESS_REGISTRATION]({ commit }) {
        commit(MutationType.SET_API_ACCESS_REGISTRATION, {
            accessKey: "",
            provider: "",
            emailId: "",
            phoneExt: 91,
            phone: null,
            firstName: "",
            lastName: "",
            userJobTitle: "",
            workspaceName: "",
            workspaceUrl: "",
            isTermOfUseAccepted: true,
            isPromotionalEmailAccepted: true,
        })
    },
    [ActionTypes.SET_AUTH_USER]({ commit }, userDetails) {
        commit(MutationType.SET_AUTH_USER, userDetails)
    },
    [ActionTypes.SET_CURRENT_USER_ROLE]({ commit }, role) {
        commit(MutationType.SET_CURRENT_USER_ROLE, role)
    },
    [ActionTypes.EMPTY_AUTH_USER]({ commit }) {
        commit(MutationType.SET_AUTH_USER, {
            sessionAccessKey: "",
            firstName: "",
            lastName: "",
            phone: null,
            phoneExt: 91,
            id: "",
            defaultLanguage: "en",
            isEmailVerified: false,
        })
    },
    [ActionTypes.SET_API_ACCESS_LOGIN]({ commit }, loginObject) {
        commit(MutationType.SET_API_ACCESS_LOGIN, loginObject)
    },
    [ActionTypes.SET_API_DEFAULT_LOGIN_DETAILS](
        { commit },
        defaultLoginObject
    ) {
        commit(MutationType.SET_API_DEFAULT_LOGIN_DETAILS, defaultLoginObject)
    },
    [ActionTypes.EMPTY_API_ACCESS_LOGIN]({ commit }) {
        commit(MutationType.SET_API_ACCESS_LOGIN, {
            emailId: "",
            accessKey: "",
        })
    },
    [ActionTypes.LOG_OUT]({ commit, dispatch }) {
        dispatch(ActionTypes.EMPTY_AUTH_USER)
        dispatch(WorkspaceActions.SET_WORKSPACE_URL, "")
        dispatch(ActionTypes.EMPTY_API_ACCESS_LOGIN)
        dispatch(ActionTypes.EMPTY_API_ACCESS_REGISTRATION)
        dispatch(WorkspaceActions.SET_TERMS_URL, "")
        dispatch(WorkspaceActions.SET_PRIVACY_URL, "")
        dispatch(GlobalActions.SET_IS_ADACOMPLIANCE, true)
        const { deleteSessionCookie } = helperFunction()
        deleteSessionCookie()
        window.Intercom("shutdown")
        localStorage.removeItem("production-testing")
        if (window.location.href.includes("testlify.local")) {
            window.open(
                `http://app.testlify.localhost:3000/login?action=clear`,
                "_self"
            )
        } // navigate user away from the workspace domain to main domain
        else if (!window.location.href.includes("testlify.com")) {
            window.open(`/`, "_self")
        } else {
            // action=clear is important to run EMPTY_API_ACCESS_LOGIN in beforeRoute for Login (route.ts) on app.testlify domain
            window.open(`https://app.testlify.com/login?action=clear`, "_self")
        }
    },
    [ActionTypes.LOG_OUT_WITHOUT_REDIRECTION]({ commit, dispatch }) {
        dispatch(ActionTypes.EMPTY_AUTH_USER)
        dispatch(WorkspaceActions.SET_WORKSPACE_URL, "")
        dispatch(ActionTypes.EMPTY_API_ACCESS_LOGIN)
        dispatch(ActionTypes.EMPTY_API_ACCESS_REGISTRATION)
        dispatch(GlobalActions.SET_IS_ADACOMPLIANCE, true)
        const { deleteSessionCookie } = helperFunction()
        deleteSessionCookie()
        localStorage.removeItem("production-testing")
    },
}
