import { useRoute } from "vue-router"

const resellerStagingHosts = ["assessments.join-staging.com"]
export default function useOAuth() {
    let API_DOMAIN = "https://api.testlify.com"
    const SAUDI_ARABIA = "Saudi Arabia"
    const route = useRoute()
    let region
    if (route?.query.region) {
        region = route.query.region === "eu" ? "Europe" : "Saudi Arabia"
    } else if (localStorage["selectedDataRegion"]) {
        region = localStorage.getItem("selectedDataRegion")
    } else {
        region = "Europe"
    }

    if (
        window.location.href.includes("localhost") ||
        window.location.href.includes("vercel") ||
        resellerStagingHosts.includes(window.location.host)
    ) {
        API_DOMAIN = "https://master.jenkins.testlify.dev"
        // If you need to connect to api branch uncomment and replace the branch id in the below code
        // API_DOMAIN = `https://{branchid}.jenkins.testlify.dev`
    }

    if (region && region.includes(SAUDI_ARABIA)) {
        API_DOMAIN = "https://api-ksa.testlify.com"
    }

    const API_PREFIX = `${API_DOMAIN}/v1`
    return { API_PREFIX }
}
