import { ActionTypes as StatActions } from "@/store/modules/global/actions"
import { useStore } from "@/store"

export default function useTranslation() {
    const languageMap: Record<string, string> = {
        en: "https://cdn.testlify.com/translation-files/EMPLOYER/en.json",
        ar: "https://cdn.testlify.com/translation-files/EMPLOYER/ar.json",
        de: "https://cdn.testlify.com/translation-files/EMPLOYER/de.json",
        el: "https://cdn.testlify.com/translation-files/EMPLOYER/el.json",
        es: "https://cdn.testlify.com/translation-files/EMPLOYER/es.json",
        fr: "https://cdn.testlify.com/translation-files/EMPLOYER/fr.json",
        it: "https://cdn.testlify.com/translation-files/EMPLOYER/it.json",
        ja: "https://cdn.testlify.com/translation-files/EMPLOYER/ja.json",
        nl: "https://cdn.testlify.com/translation-files/EMPLOYER/nl.json",
        pt: "https://cdn.testlify.com/translation-files/EMPLOYER/pt.json",
        vi: "https://cdn.testlify.com/translation-files/EMPLOYER/vi.json",
    }
    const store = useStore()
    const CandidateLanguageList = [
        {
            id: "644a2547a8b497ca398ea9f7",
            language: "English",
            code: "en",
        },
        {
            id: "65829984dede20c41aaa496a",
            language: "Arabic",
            code: "ar",
        },
        {
            id: "644a30cd519da9ffd6d2445d",
            language: "Deutsch",
            code: "de",
        },
        {
            id: "644a3209519da9ffd6d24480",
            language: "Dutch",
            code: "nl",
        },
        {
            id: "644a301a519da9ffd6d24451",
            language: "Español",
            code: "es",
        },
        {
            id: "644a31b6519da9ffd6d2447c",
            language: "French",
            code: "fr",
        },
        {
            id: "644a3187519da9ffd6d24473",
            language: "Italian",
            code: "it",
        },
        {
            id: "669a6a2651c5f8e127ef0ff3",
            language: "Greek",
            code: "el",
        },
        {
            id: "644a3150519da9ffd6d2446f",
            language: "Japanese",
            code: "ja",
        },
        {
            id: "644a3132519da9ffd6d2446b",
            language: "Portuguese",
            code: "pt",
        },
        {
            id: "669a7bb351c5f8e127ef10c5",
            language: "Chinese",
            code: "zh",
        },
        {
            id: "669a7bc151c5f8e127ef10cb",
            language: "Dansk",
            code: "da",
        },
        {
            id: "669a7bdf51c5f8e127ef10d7",
            language: "Hebrew",
            code: "he",
        },
        {
            id: "669a7bf751c5f8e127ef10dd",
            language: "Korean",
            code: "ko",
        },
        {
            id: "669a7cba51c5f8e127ef1109",
            language: "Norsk",
            code: "no",
        },
        {
            id: "669a7ccb51c5f8e127ef110f",
            language: "Polski",
            code: "pl",
        },
        {
            id: "669a7ced51c5f8e127ef1123",
            language: "Russian",
            code: "ru",
        },
        {
            id: "669a7cfa51c5f8e127ef1129",
            language: "Svenska",
            code: "sv",
        },
        {
            id: "669a7d0b51c5f8e127ef112f",
            language: "Turkish",
            code: "tr",
        },
        // {
        //     id: "669a7d1e51c5f8e127ef1135",
        //     language: "Vietnamese",
        //     code: "vi",
        // },
        // {
        //     id: "6716351b2abde3d8cb0fd7d7",
        //     language: "Hindi",
        //     code: "hi",
        // },
        // {
        //     id: "671635272abde3d8cb0fd7dd",
        //     language: "Marathi",
        //     code: "mar",
        // },
    ]

    const EmployerLanguageList = [
        {
            id: "644a2547a8b497ca398ea9f7",
            language: "English",
            code: "en",
        },
        {
            id: "655db68267e143aa5d8d11f3",
            language: "Arabic",
            code: "ar",
        },
        {
            id: "644a30cd519da9ffd6d2445d",
            language: "Deutsch",
            code: "de",
        },
        {
            id: "644a3209519da9ffd6d24480",
            language: "Dutch",
            code: "nl",
        },
        {
            id: "644a301a519da9ffd6d24451",
            language: "Español",
            code: "es",
        },
        {
            id: "644a31b6519da9ffd6d2447c",
            language: "French",
            code: "fr",
        },
        {
            id: "669a508f5de8f4e137e89929",
            language: "Greek",
            code: "el",
        },
        {
            id: "644a3187519da9ffd6d24473",
            language: "Italian",
            code: "it",
        },
        {
            id: "644a3150519da9ffd6d2446f",
            language: "Japanese",
            code: "ja",
        },
        {
            id: "644a3132519da9ffd6d2446b",
            language: "Portuguese",
            code: "pt",
        },
        // {
        //     id: "66b4b3eebbe7941a84ee8879",
        //     language: "Vietnamese",
        //     code: "vi",
        // },
        // {
        //     id: "6716351b2abde3d8cb0fd7d7",
        //     language: "Hindi",
        //     code: "hi",
        // },
        // {
        //     id: "671635272abde3d8cb0fd7dd",
        //     language: "Marathi",
        //     code: "mar",
        // },
    ]

    const apiGetLanguages = async (userType: string) => {
        if (userType === "EMPLOYER") {
            store.dispatch(StatActions.SET_LANGUAGE_LIST, EmployerLanguageList)
            return EmployerLanguageList
        } else {
            store.dispatch(StatActions.SET_LANGUAGE_LIST, CandidateLanguageList)
            return CandidateLanguageList
        }
    }

    const apiGetLanguageJson = async (lang: string) => {
        const url = languageMap[lang] || languageMap["en"] // Default to English if language is not found

        try {
            const response = await fetch(url + "?t=" + new Date().getTime())
            const languageData = await response.json()

            if (languageData) {
                store.dispatch(StatActions.SET_LANGUAGE_JSON, languageData)
            }

            return languageData
        } catch (error) {
            //bearer:disable javascript_lang_logger_leak
            console.error("Failed to fetch language data:", error)
            return null
        }
    }

    return {
        apiGetLanguages,
        apiGetLanguageJson,
    }
}
