import { MutationTree } from "vuex"
import type { State } from "./state"
import { TypeCompanyQuestion, TypeCompanyTest } from "./types"

export enum MutationType {
    SET_COMPANY_TEST_DETAILS = "SET_COMPANY_TEST_DETAILS",
    SET_COMPANT_TEST_SKILLS = "SET_COMPANT_TEST_SKILLS",
    OPEN_SINGLE_SELECT_MODAL = "OPEN_SINGLE_SELECT_MODAL",
    OPEN_MULTIPLE_SELECT_MODAL = "OPEN_MULTIPLE_SELECT_MODAL",
    SET_COMPANY_TEST_QUESTION_LIST = "SET_COMPANY_TEST_QUESTION_LIST",
    SET_ACTIVE_ACCORDION = "SET_ACTIVE_ACCORDION",
    REMOVE_COMPANY_QUESTION_FROM_CART = "REMOVE_COMPANY_QUESTION_FROM_CART",
}

export type Mutations = {
    [MutationType.SET_COMPANY_TEST_DETAILS](
        state: State,
        list: TypeCompanyTest
    ): void
    [MutationType.OPEN_SINGLE_SELECT_MODAL](state: State, value: boolean): void
    [MutationType.OPEN_MULTIPLE_SELECT_MODAL](
        state: State,
        value: boolean
    ): void
    [MutationType.SET_COMPANY_TEST_QUESTION_LIST](
        state: State,
        list: TypeCompanyQuestion
    ): void
    [MutationType.SET_COMPANT_TEST_SKILLS](state: State, skills: any): void
    [MutationType.SET_ACTIVE_ACCORDION](
        state: State,
        activeAccordion: string
    ): void
    [MutationType.REMOVE_COMPANY_QUESTION_FROM_CART](
        state: State,
        cartItemIndex: number
    ): void
}

export const mutations: MutationTree<State> & Mutations = {
    [MutationType.SET_COMPANY_TEST_DETAILS](state, list) {
        state.companyTest = { ...list }
    },
    [MutationType.OPEN_SINGLE_SELECT_MODAL](state, value: boolean) {
        state.openSingleSelectModal = value
    },
    [MutationType.OPEN_MULTIPLE_SELECT_MODAL](state, value: boolean) {
        state.openMultipleSelectModal = value
    },
    [MutationType.SET_COMPANY_TEST_QUESTION_LIST](state, list) {
        state.companyQuestions = list
    },
    [MutationType.SET_COMPANT_TEST_SKILLS](state, skills) {
        const skillsArray = skills["skills"]
        state.companyTest.coveredSkillList = skillsArray
    },
    [MutationType.SET_ACTIVE_ACCORDION](state, activeAccordion: string) {
        let activeName = document.getElementById(activeAccordion.toString())
            .childNodes[0].childNodes[0] as HTMLElement | null
        activeName.scrollIntoView(true)
        window.scrollBy(0, -30)
        state.activeAccordion = activeAccordion
    },
    [MutationType.REMOVE_COMPANY_QUESTION_FROM_CART](state, cartItemIndex) {
        state.companyQuestions.splice(cartItemIndex, 1)
    },
}
